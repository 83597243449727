import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../../hooks/useMediaQuery"
import { Link } from "gatsby"

const ItConsulting = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Maximize Pipefy with a Consultative Partner
            </h2>
            <p className="mx-auto" style={{ maxWidth: "825px" }}>
              As your strategic partner, we ensure your workflows are built for
              efficiency, scalability, and real business impact.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label for="tab1" class="tabs__label mx-auto">
                About Quandary
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/17116abae94c1f521cc7b78a9429d25f7856dd6f-1280x960.webp"
                      alt="About Quandary"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">About Quandary</h3>
                    <p className="my-4 text-left-mobile">
                      We are a consulting firm based in Denver, Colorado with a
                      simple mission: to design better systems that save
                      businesses time and money. Business transformation isn’t
                      easy and it’s never “finished”. That’s why we position
                      ourselves as an extension of our client’s business. We
                      prioritize our client success (both now and in the future)
                      and we provide a level of responsiveness unheard of in
                      technology consulting.
                    </p>
                    <Link
                      className="btn btn-primary btn-arrow mb-5 career-btn"
                      aria-label="Quandary Connect"
                      to="/about-us/"
                    >
                      Learn More About Quandary
                    </Link>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label for="tab2" class="tabs__label mx-auto">
                Our Team
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Our Team</h3>
                    <p className="my-4 text-left-mobile">
                      We hire the person. Not the position. Each person on our
                      team adds to our company culture and values. They care
                      about the work they do. And they strive to help our
                      clients succeed. Our leadership team values transparency
                      and feedback. They’re willing to admit mistakes, recognize
                      individual talents, support opinions, and listen to
                      disagreements, all while considering the team’s overall
                      health and well-being.
                    </p>
                    <Link
                      className="btn btn-primary btn-arrow mb-5 career-btn"
                      aria-label="Quandary Connect"
                      to="/about-us/"
                    >
                      Meet the Team
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/9f93284c51f44089ccfdb656b5919f3261a9ea37-2016x1512.webp"
                      alt="Our Team"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label for="tab3" class="tabs__label mx-auto">
                Our Clients
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f1c838ed0c3c4f7c7567f4ad34c273125adce4db-2016x1512.webp"
                      alt="Our Clients"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Our Clients</h3>
                    <p className="my-4 text-left-mobile">
                      We work with growing businesses that understand the
                      powering of investing in better systems and processes. And
                      we work closely with our clients to seamlessly guide them
                      to the right solutions to automate workflows while
                      providing deeper data insights. With a 100% approval rate,
                      our results speak for themselves.
                    </p>
                    <Link
                      className="btn btn-primary btn-arrow mb-5 career-btn"
                      aria-label="Quandary Connect"
                      to="/case-studies/"
                    >
                      See Our Success Stories
                    </Link>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label
                for="tab4"
                class="tabs__label mr-3 ml-auto mt-2 text-center"
              >
                Our Remote Work Lifestyle
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Our Remote Work Lifestyle
                    </h3>
                    <div className="my-4 text-left-mobile">
                      <p>
                        We support a positive, healthy work-life balance for our
                        team. And we ensure this by offering:
                      </p>
                      <ul>
                        <li>A Transparent Work Culture</li>
                        <li>Remote Work Environment</li>
                        <li>Unlimited PTO</li>
                        <li>Flexible Scheduling</li>
                        <li>Creative Incentives</li>
                        <li>Wellness Services</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/040a8281a8d0f0bd268ff4cbf235fd44963a556b-2312x1736.webp"
                      alt=" Our Remote Work Lifestyle"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">About Quandary</h3>
                    <p className="my-4 ">
                      We are a consulting firm based in Denver, Colorado with a
                      simple mission: to design better systems that save
                      businesses time and money. Business transformation isn’t
                      easy and it’s never “finished”. That’s why we position
                      ourselves as an extension of our client’s business. We
                      prioritize our client success (both now and in the future)
                      and we provide a level of responsiveness unheard of in
                      technology consulting.
                    </p>
                    <Link
                      className="btn btn-primary btn-arrow  mb-lg-0 mb-3 career-btn"
                      aria-label="Quandary Connect"
                      to="/about-us/"
                    >
                      Learn More About Quandary
                    </Link>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/17116abae94c1f521cc7b78a9429d25f7856dd6f-1280x960.webp"
                      alt="About Quandary"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Our Team</h3>
                    <p className="my-4 ">
                      We hire the person. Not the position. Each person on our
                      team adds to our company culture and values. They care
                      about the work they do. And they strive to help our
                      clients succeed. Our leadership team values transparency
                      and feedback. They’re willing to admit mistakes, recognize
                      individual talents, support opinions, and listen to
                      disagreements, all while considering the team’s overall
                      health and well-being.
                    </p>
                    <Link
                      className="btn btn-primary btn-arrow mb-3 career-btn"
                      aria-label="Quandary Connect"
                      to="/about-us/"
                    >
                      Meet the Team
                    </Link>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/9f93284c51f44089ccfdb656b5919f3261a9ea37-2016x1512.webp"
                      alt="Our Team"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Our Clients</h3>
                    <p className="my-4 ">
                      We work with growing businesses that understand the
                      powering of investing in better systems and processes. And
                      we work closely with our clients to seamlessly guide them
                      to the right solutions to automate workflows while
                      providing deeper data insights. With a 100% approval rate,
                      our results speak for themselves.
                    </p>
                    <Link
                      className="btn btn-primary btn-arrow mb-lg-0 mb-3 career-btn"
                      aria-label="Quandary Connect"
                      to="/case-studies/"
                    >
                      See Our Success Stories
                    </Link>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f1c838ed0c3c4f7c7567f4ad34c273125adce4db-2016x1512.webp"
                      alt=" Our Clients"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Our Remote Work Lifestyle</h3>
                    <div className="my-4 ">
                      <p>
                        We support a positive, healthy work-life balance for our
                        team. And we ensure this by offering:
                      </p>
                      <ul>
                        <li>A Transparent Work Culture</li>
                        <li>Remote Work Environment</li>
                        <li>Unlimited PTO</li>
                        <li>Flexible Scheduling</li>
                        <li>Creative Incentives</li>
                        <li>Wellness Services</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/040a8281a8d0f0bd268ff4cbf235fd44963a556b-2312x1736.webp"
                      alt=" Our Remote Work Lifestyle"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default ItConsulting
