import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { StaticImage } from "gatsby-plugin-image"

import ScrollBtn from "../button/scroll-btn"
import team from "../../images/new-images/img-abouteambanner.jpg"

import "./hero.css"

const TeamBannerHome = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "new-images/Team-Photo-2.jpg" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        className="team"
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        // className="hero-banner hero-whitefade-about"
        // preserveStackingContext
      >
        <div className="container">
          <div className="row">
            <div className="col-lg mr-auto mb-md-4">
              <div
                className="about-header about-us-hero"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                {/* <p className="text-center h5 text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                  ABOUT US
                </p> */}
                <h2 className="text-center page-title font-weight-medium mt-0 mb-3">
                  Meet Your Innovation Team
                </h2>
                <p
                  className="mx-auto text-center"
                  style={{ maxWidth: "825px" }}
                >
                  We have a deep bench with the expertise to turn your ideas
                  into exciting features.
                </p>

                {/* <div className="d-flex justify-content-center pb-4">
                  <ScrollBtn to="/about-us/" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="home-team">
            <img
              placeholder="blurred"
              className="img-fluid"
              src={team}
              alt="Team"
              layout="constrained"
              loading="lazy"
            />
          </div>
        </div>

        <div className="text-center pt-4">
          <Link
            className="btn btn-primary btn-arrow text-center"
            to="/about-us/"
            style={{ maxWidth: "fit-content " }}
          >
            Learn About Quandary
          </Link>
        </div>
      </div>
    </>
  )
}

export default TeamBannerHome
