import React, { useEffect, useState } from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import { StaticImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import TeamBanner from "../components/hero/banner-hometeam.js"
import { IoMdQuote } from "react-icons/io"

import { LazyLoadComponent } from "react-lazy-load-image-component"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import CareerMultiTab from "../components/multi-tab/career-multi-tab"
import CareerSwiper from "../components/swiper/career-swiper"

const QuandaryValues = loadable(() =>
  pMinDelay(import("../components/new-quandary/quandary-value-career"), 500)
)
const Referral = loadable(() =>
  pMinDelay(import("../components/WorkLifeSwiper"), 500)
)
const Testimonials = loadable(() =>
  pMinDelay(import("../components/testimonials-career"), 500)
)
const Banner = loadable(() =>
  pMinDelay(import("../components/hero/banner-career"), 500)
)

const Careers = props => {
  const { heroImage, allSanityCareer } = useStaticQuery(
    graphql`
      {
        heroImage: file(relativePath: { eq: "new-images/career_bgimg.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        allSanityCareer {
          totalCount
          edges {
            node {
              _id
              _createdAt
              datePosted
              description
              employmentType
              excerpt
              id
              salary
              title
              location
              cta
              slug {
                current
              }
            }
          }
          location: group(field: location) {
            fieldValue
          }
          title: group(field: title) {
            fieldValue
          }
        }
      }
    `
  )

  const [searchText, setSearchText] = useState("")
  const [jobItems, setJobItems] = useState(
    allSanityCareer ? allSanityCareer.edges : []
  )

  const strLowerCase = str => (str ? str.toLowerCase() : false)
  const containStr = (str, text) =>
    strLowerCase(str) && strLowerCase(str).includes(text)
  const handleJobItems = () => {
    return allSanityCareer && allSanityCareer.edges
      ? allSanityCareer.edges.filter(item => {
          const { excerpt, location, title } = item.node
          return (
            containStr(excerpt, searchText) ||
            containStr(location, searchText) ||
            containStr(title, searchText)
          )
        })
      : []
  }

  // useEffect(() => {
  //   // Get a reference to the iframe element
  //   const iframe = document.getElementById("jobposting")

  //   // Access the content window of the iframe
  //   const iframeDocument = iframe.contentDocument || iframe.contentWindow

  //   // Manipulate the elements inside the iframe
  //   const iframeElement = iframeDocument.getElementsByClassName(
  //     "jobs-navbar"
  //   )[0]
  //   iframeElement.style.color = "red"

  //   // Clean up the effect
  //   return () => {
  //     // Reset any changes made inside the iframe before the component unmounts
  //     iframeElement.style.color = "" // or whatever reset value you need
  //   }
  // }, []) // Empty dependency array to run the effect only once

  useEffect(() => {
    if (allSanityCareer) {
      setJobItems(allSanityCareer.edges)
    }
  }, [])
  useEffect(() => {
    setJobItems(handleJobItems)
  }, [searchText])

  useEffect(() => {
    const els = document.getElementsByClassName("btn btn-link btn-icon-right")
    console.log("test", els)
  })

  console.log("jobItems => ", jobItems)
  console.log("allSanityCareer => ", allSanityCareer)
  return (
    <Layout banner={<Banner />} location={props.location}>
      <Seo
        title="Careers | Quandary Consulting Group"
        description="Join our team of technology consultants and integration specialists to help businesses reduce menial tasks. Great work life balance. Remote team. Learn more."
      />
      <section id="jobposting" className="jazzHR-plugin py-5">
        <div className="mobile-overlay" />
        <LazyLoadComponent>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="relative">
                  <iframe
                    scrolling="yes"
                    name="resumator-job-frame"
                    id="resumator-job-frame"
                    title="resumator job"
                    class="resumator-advanced-widget"
                    src="https://quandaryconsultinggroup.applytojob.com/apply/"
                    width="100%"
                    height="500px"
                    frameborder="0"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    style={{ overflow: "scroll" }}
                    allowTransparency="true"
                  ></iframe>

                  <div className="nav-jobs-posting mx-auto w-100 text-center justify-content-between d-flex">
                    {/* <a
                      href="https://quandaryconsultinggroup.applytojob.com/apply"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="view-all-jobs"
                    >
                      <i class="fa fa-chevron-left mr-2"></i>
                      View All Jobs
                    </a> */}
                    {/* <a href="/" className="view-our-website">
                      View Our Website
                      <i class="fa fa-chevron-right ml-2"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LazyLoadComponent>
      </section>
      <CareerMultiTab />
      <section
        id="logos-wrapper"
        className="perks bg-white quandary-connect-glance"
        style={{ padding: "3rem 0 0" }}
      >
        <h2 className="my-2 text-center">Our Perks and Benefits</h2>
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/savings.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">401K</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/dental-care.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Dental Insurance</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/health-insurance.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Life Insurance</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/healthcare.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Medical Insurance</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/spiritual-wellness.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Wellness Service</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/pto.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Unlimited PTO</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/reimburse.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">
                  Professional Development <br /> Reimbursement
                </p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/profit.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">
                  Year-End Profit Sharing
                  <br /> and Bonus
                </p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center mx-auto">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/computer.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">
                  Company Equipment <br />
                  (Mac or PC)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TeamBanner />
      <section style={{ padding: "1rem 0 3rem" }} className="peaks">
        <div
          className="container"
          style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
        >
          <div className="row">
            <div className="col-lg-8 mx-auto text-center pt-4">
              <h2>Our Work-Life Balance</h2>
            </div>
            <div className="col-lg-12 mt-0">
              <Referral />
            </div>
          </div>
        </div>
      </section>
      <section className="peaks" style={{ paddingBottom: "3rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center text-left-mobile">
              <h2>
                Our <span>"</span>PEAKS<span>"</span> Values
              </h2>
              <p>
                PEAKS serves as the foundation for our team values, guiding us
                toward continuous improvement in the work we do and the results
                we provide.
              </p>
            </div>
            <div className="col-lg-12 mt-3">
              <QuandaryValues />
            </div>
          </div>
        </div>
      </section>
      {/* <CareerSwiper /> */}
      <section className="bg-grey">
        <Testimonials />
      </section>
      <LazyLoadComponent>
        <section>
          <div className="container-fluid px-0">
            <div
              className="bg-case-study careers-banner"
              style={{
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row ">
                  <div className="col-lg-11 mx-auto text-center">
                    <div
                      className="hero-custom-content-quote text-center d-flex align-items-center flex-column flex-sm-row"
                      style={{ gap: "1rem" }}
                    >
                      <div>
                        <h1 className="font-weight-bold m-0 text-white">
                          <IoMdQuote />
                        </h1>
                        <h2 className="page-title font-weight-medium mt-2 text-white">
                          Our value stems from growing our clients. Our success
                          stems from growing our teammates.
                        </h2>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center flex-column"
                        style={{ gap: "0.5rem" }}
                      >
                        <img
                          src="https://cdn.sanity.io/images/55lcecww/production/214de3faacf7010e5e2e27dcb14f3a8ddcbc7358-862x745.jpg"
                          alt="Kevin Shuler"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            border: "1px solid #0005",
                          }}
                        />
                        <p className="h4 page-title font-weight-medium text-white">
                          Kevin Shuler, Quandary CEO
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LazyLoadComponent>
    </Layout>
  )
}

export default Careers
